export const AUTH_USER_RETURN = "AUTH_USER_RETURN";
export const AUTH_USER_SAGAS = "AUTH_USER_SAGAS";

export const authUserSagas = (uuid, xAccess) =>({
    type: AUTH_USER_SAGAS,
    payload : uuid,
    token: xAccess
})

export const authUserReturn = (result) =>({
    type: AUTH_USER_RETURN,
    payload : result
})